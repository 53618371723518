import { IconamoonDeliveryFreeLight } from "public/icons/DeliveryFree";

export const Banner = () => {
  return (
    <div className="p-4 pb-0 lg:max-w-7xl m-auto">
      <div className="text-md text-accent p-4 border-2 border-primary rounded-xl">
        <div className="flex">
          <IconamoonDeliveryFreeLight />
          &ensp;
          <p className="outfit-font text-base lg:text-lg">
            <b>¡Asegura tu despacho!</b>
          </p>
        </div>
        <p className="outfit-font text-justify text-sm lg:text-base">
          Durante la semana de fiestas patrias haremos despacho{" "}
          <u>
            {" "}
            <b>únicamente el martes 17 de septiembre</b>
          </u>
          . Recuerda que tienes hasta el <b>lunes a las 14:00</b> para realizar
          tu pedido.
        </p>
      </div>
    </div>
  );
};
